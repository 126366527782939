import client from "./client";

const getCity = (id) => {
  return client
    .get(`/city/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => error.response);
};

const getCities = (filter) => {
  return client
    .get(`/cities`, filter)
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => error.response);
};

const createCity = (data) => {
  return client
    .post("/city", data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const updateCity = (id, data) => {
  return client
    .put(`/city/${id}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => error.response);
};

const deleteCities = (data) => {
  return client
    .put(`/city`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const cityAPI = {
  getCity,
  createCity,
  updateCity,
  deleteCities,
  getCities,
};

export default cityAPI;
