<template>
    <div>
        <a-form :form="form" @submit="handleSubmit">
            <a-row type="flex" :gutter="24">
                <a-col :span="24" :lg="24" class="mb-24">
                    <a-card :bordered="false" class="header-solid h-full"
                        :bodyStyle="{ paddingTop: 0, paddingBottom: '40px' }">
                        <template #title>
                            <h5 class="mb-0">City details</h5>
                        </template>
                        <a-row :gutter="[12]">
                            <a-col :span="6">
                                <a-form-item class="mb-10" label="Name" :colon="false">
                                    <a-input v-model="name" placeholder="eg. Barcelona" />
                                </a-form-item>
                            </a-col>
                            <!-- <a-col v-if="isEdit" :span="6"> -->
                            <!--     <a-form-item class="mb-10" label="Status" :colon="false"> -->
                            <!--         <a-switch v-model="is_active" /> -->
                            <!--     </a-form-item> -->
                            <!-- </a-col> -->
                        </a-row>
                    </a-card>
                </a-col>
                <a-button type="primary" html-type="submit"> Submit </a-button>
            </a-row>
        </a-form>
    </div>
</template>

<script>
import cityAPI from '../../api/city';
import router from '../../router';

export default {
    data() {
        return {
            name: '',
            cityEditData: null,
            isEdit: false,
            // is_active: false,
            form: this.$form.createForm(this, { name: "editCity" }),
        };
    },
    methods: {
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, city) => {
                if (!err) {
                    city.name = this.name;
                    // city.is_active = this.is_active;
                    if (!this.isEdit) {
                        cityAPI.createCity(city).then((response) => {
                            if (response.status == 200) {
                                router.push('/cities/cities-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/cities/cities-list');
                        })
                    } else {
                        cityAPI.updateCity(this.cityEditData.data.id, city).then((response) => {
                            if (response.status == 200) {
                                router.push('/cities/cities-list')
                            }
                        }).catch((error) => {
                            console.log(error);
                            router.push('/cities/cities-list');
                        })
                    }
                }
            });
        },
        async getCityById(id) {
            return cityAPI.getCity(id);
        }
    },
    async mounted() {
        if (this.$route.params.id !== undefined) {
            this.cityEditData = await this.getCityById(this.$route.params.id); // get city name by id to edit it
            this.isEdit = true;
            if (this.cityEditData.code == 200) {
                this.name = this.cityEditData.data.name;
            }
        }
    },
};
</script>
